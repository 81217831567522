.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.failedText {
    color: red;
    text-align: center;
}

.secret {
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: white;
    size: 20px;
    text-decoration: none;
}